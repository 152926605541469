import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Home" },
  },
  {
    path: "/aritcle/:title",
    name: "Article",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Article.vue"),
    meta: { title: "Article" },
  },
  {
    path: "/stockGraph",
    name: "Stock Graph",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Stock Graph" */ "../views/Stock.vue"),
    meta: { title: "Stock Graph" },
  },
  {
    path: "/user/profile",
    name: "User Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/user/Profile.vue"),
    meta: { title: "Profile" },
    beforeEnter: guardedRoute,
  },
  
  {
    path: "/user/region",
    name: "Region List",
    component: () =>
      import(
        /* webpackChunkName: "regionList" */ "../views/user/RegionList.vue"
      ),
    meta: { title: "Region List" },
    beforeEnter: guardedRoute,
  },

  {
    path: "/user/region/:id",
    name: "Region Detail",
    component: () =>
      import(
        /* webpackChunkName: "region detail" */ "../views/user/RegionDetail.vue"
      ),
    meta: { title: "Region Detail" },
    beforeEnter: guardedRoute,
  },
  
  {
    path: "/user/captable",
    name: "Captable List",
    component: () =>
      import(
        /* webpackChunkName: "Captable list" */ "../views/user/CaptableList.vue"
      ),
    meta: { title: "Captable List" },
    beforeEnter: guardedRoute,
  },
  {
    path: "/user/captable/:id",
    name: "Captable Detail",
    component: () =>
      import(
        /* webpackChunkName: "Captable detail" */ "../views/user/CaptableDetail.vue"
      ),
    meta: { title: "Captable Detail" },
    beforeEnter: guardedRoute,
  },
  {
    path: "/user/captable/:id/investor/:investor_id",
    name: "Captable Investor Detail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Captable invsetor detail" */ "../views/user/CaptableInvestor.vue"
      ),
    meta: { title: "Captable Detail" },
    beforeEnter: guardedRoute,
  },
  {
    path: "/user/investment",
    name: "Investment List",
    component: () =>
      import(
        /* webpackChunkName: "Captable list" */ "../views/user/InvestmentList.vue"
      ),
    meta: { title: "Investment List" },
    beforeEnter: guardedRoute,
  },
  {
    path: "/user/investment/:id",
    name: "Investment Detail",
    component: () =>
      import(
        /* webpackChunkName: "Captable detail" */ "../views/user/InvestmentDetail.vue"
      ),
    meta: { title: "Investment Detail" },
    beforeEnter: guardedRoute,
  },
  {
    path: "/user/investment/:id/investor/:investor_id",
    name: "Investment Investor Detail",
    component: () =>
      import(
        /* webpackChunkName: "Captable detail" */ "../views/user/InvestmentInvestor.vue"
      ),
    meta: { title: "Investor Detail" },
    beforeEnter: guardedRoute,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function guardedRoute(to, from, next) {
  if (!localStorage.getItem("user")) next({ name: "Home" });
  next();
}

export default router;
