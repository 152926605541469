<template>
    <b-container fluid="lg">
      <HelloWorld />
      <div class="d-flex mt-5 mb-5">
        <b-icon-chevron-double-down
          class="ml-auto mr-auto text-muted"
          scale="1.5"
        />
      </div>
      <hr />

      <b-row :key="headlines.length">
        <b-col
          cols="12"
          sm="4"
          v-for="headline in headlines"
          :key="headline.id"
        >
          <News :headline="headline" />
        </b-col>
        <b-col cols="12" class="d-flex">
          <b-button
            v-if="!noMore"
            @click="fetchMore()"
            class="bg-white border-white mr-auto ml-auto text-primary"
          >
            more news ...
          </b-button>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import siteMeta from '../site'
import News from '../components/News'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    News,
  },
  data: () => {
    return {
      headlines: [],
      start: 0,
      limit: 12,
      noMore: false,
      isLoading: false,
    }
  },

  methods: {
    fetchMore() {
      this.$store.isLoading = true
      fetch(
        siteMeta.cmsUrl +
          `headlines/?keyword=${siteMeta.cmsTag}&_sort=id:DESC&_limit=${this.limit}&_start=${this.start}`,
      )
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.noMore = data.length < this.limit ? true : false
          this.headlines = this.headlines.concat(data)
          this.start = this.headlines.length
          console.log(this.start)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.$store.isLoading = false
        })
    },
  },
  created() {
    console.log(siteMeta)
    this.fetchMore()
  },
}
</script>

<style scoped></style>
