<template>
  <b-card no-body class="card h-100 bg-white border-white mb-5">
    <img class="card-img" :src="headline.urlToImage" />
    <div class="card-body">
      <div class="card-title">
        <p class="text-muted">
          {{ headline.title }}
          <br />
          <!--{{ headline.description }}-->
          <a :href="headline.url">
            {{
              headline.created_at ? headline.created_at.slice(0, 10) + ' ' : ''
            }}
            {{ headline.source }}
          </a>
        </p>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: ['headline'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
img {
  width: 100%;
  height: 230px;
  object-fit: cover;
}
</style>
