<template>
  <div id="app" class="d-flex flex-column">
    <b-container fluid>
      <b-navbar toggleable="md">
        <b-navbar-brand :to="{ name: 'Home' }">
          <img src="./assets/logo.png" style="height: 60px;" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav></b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="mr-auto">
            <b-nav-item class="mx-1" :to="{ name: 'Home' }">home</b-nav-item>
            <b-nav-item
              class="mx-1"
              :to="{ name: 'Article', params:{title: 'About NextRental'} }"
            >about</b-nav-item>
          </b-navbar-nav>
          <!--
            <b-nav-item class="mr-2" :to="{ name: 'Stock Graph' }">stock-gragh</b-nav-item>
          -->
          <b-navbar-nav>
            <b-nav-item v-if="isLoggedIn" class="mx-1" :to="{ name: 'Investment List' }">
              <b-icon-bar-chart class="mr-1" />Investments
            </b-nav-item>
            <b-nav-item-dropdown v-if="isLoggedIn">
              <template #button-content>
                <b-icon-person class="mr-1" />{{ user.email }}
              </template>

              <b-dropdown-item v-if="isLoggedIn" :to="{ name: 'Captable List' }">
                <b-icon-house variant="dark" class="mr-1" />captables
              </b-dropdown-item>
              <b-dropdown-item v-if="isLoggedIn" :to="{ name: 'User Profile' }">
                <b-icon-pencil-square variant="dark" class="mr-1" />profile
              </b-dropdown-item>

              <b-dropdown-item @click="triggerAction('logout')">
                <b-icon-x-circle variant="dark" class="mr-1" />logout
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-form>
              <b-button
                v-if="!isLoggedIn"
                @click="triggerAction('login')"
                v-b-tooltip.hover
                title="login/signup"
                size="sm"
                variant="dark"
                pill
              >
                <b-icon-person></b-icon-person>login
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>

    <b-overlay :show="$store.isLoading">
    <b-container fluid class="min-vh-100">
      <router-view />
    </b-container>
    </b-overlay>
    <b-container fluid class="bg-light mt-auto pt-5 pb-5">
      <b-container>
        <div class="text-muted">
          <small>
            <br />
            <br />
            <br />NextRent LLC
            <br />info@nxtrent.com
            <br />777 Westchester Avenue White Plains, NY 10604, USA
          </small>
          <!--
        <b-col cols="12" sm="4">
          <b-form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            class="ml-auto"
          >
            <input type="hidden" name="form-name" value="contact" />

            <b-form-group>
              <b-input
                class="form-control-sm"
                type="email"
                name="email"
                placeholder="Your Email "
              />
            </b-form-group>
            <b-form-group>
              <b-form-textarea
                class="form-control-sm"
                name="message"
                rows="5"
                placeholder="Message to NextRental LLC"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group>
              <b-button block size="sm" type="submit">Send</b-button>
            </b-form-group>
          </b-form>
        </b-col>
          -->
        </div>
      </b-container>
    </b-container>
  </div>
</template>
<script>
import site from "./site";
import netlifyIdentity from "netlify-identity-widget";
import { mapGetters, mapActions } from "vuex";

netlifyIdentity.init({
  APIUrl: site.identityUrl,
  logo: true
});
export default {
  data() {
    return {
      meta: undefined,
      currentUser: null
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    }),
    username() {
      return this.user ? this.user.username : this.user.email;
    }
  },
  methods: {
    ...mapActions("user", {
      updateUser: "updateUser"
    }),
    triggerAction(action) {
      if (action == "login" || action == "signup") {
        netlifyIdentity.open(action);
        netlifyIdentity.on(action, user => {
          this.currentUser = {
            db_token: user.app_metadata.db_token, //user profile id
            username: user.user_metadata.full_name,
            email: user.email,
            access_token: user.token.access_token,
            expires_at: user.token.expires_at,
            refresh_token: user.token.refresh_token,
            token_type: user.token.token_type
          };
          this.updateUser({
            currentUser: this.currentUser
          });
          netlifyIdentity.close();
        });
      } else if (action == "logout") {
        this.currentUser = null;
        this.updateUser({
          currentUser: this.currentUser
        });
        netlifyIdentity.logout();
        this.$router.push({ name: "Home" });
      }
    }
  },
  created: async function() {
    
    //clean up catched user information
    this.currentUser=null;
    this.$store.isLoading = false
    this.updateUser({
      currentUser: this.currentUser
    })
    netlifyIdentity.logout()

    netlifyIdentity.on("close", () => {
      this.updateUser({
        currentUser: this.currentUser
      });
    });
  }
};
</script>
<style></style>
