import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import axios from "axios";
//const axios = require("axios");

axios.interceptors.request.use(config => {
  try {
    const token = JSON.parse(window.localStorage.getItem("user")).access_token;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
  } catch (err) {
    console.log('no access token')
  }
  return config;
});

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
