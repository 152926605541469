<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <b-card class="bg-white border-white">
          <h3 class="font-weight-bold">About Us</h3>
          <p class="text-muted">
            More information about NXTrent Captable Management and NextRental
            LLC.
          </p>
          <b-nav>
            <b-nav-item
              :to="{ name: 'Article', params: { title: 'About NextRental' } }"
            >
              About our investor club
            </b-nav-item>
          </b-nav>
        </b-card>
        <b-card class="mt-1 bg-white border-white">
          <h3 class="font-weight-bold">Captable Managment</h3>
          <p class="text-muted">
            We integrate a simple captable for members to easily manage the cap
            tables of their projects
          </p>
          <b-nav>
            <b-nav-item
              :to="{ name: 'Article', params: { title: 'Captable' } }"
            >
              Captable
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'Article', params: { title: 'LLC, LP, and DST' } }"
            >
              LLC, LP, and DST
            </b-nav-item>
          </b-nav>
        </b-card>
        <b-card class="mt-1 bg-white border-white">
          <h3 class="font-weight-bold">Real Estate Investment</h3>
          <p class="text-muted">
            NextRental is focusing on local projects and servicing small
            investors
          </p>
          <b-nav>
            <b-nav-item
              :to="{ name: 'Article', params: { title: 'Equity Investment' } }"
            >
              Equity Investment
            </b-nav-item>
            <b-nav-item :to="{ name: 'Article', params: { title: 'Lending' } }">
              Lending
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'Article', params: { title: 'Property Purchase' } }"
            >
              Property Purchase
            </b-nav-item>
          </b-nav>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" class="d-flex flex-column justify-content-center">
        <b-card id="marketing-card-1" class="h-75 bg-warning text-dark">
          <div class="d-flex flex-column align-items-start h-100">
            <h1 class="font-weight-bold mt-auto">
              NXTRENT
            </h1>
            <h5 class="font-weight-light">
              We dedicate to make complex real estate investment simple, so you
              could focus on investment.
            </h5>
            <br />
            <h5 class="font-weight-light">
              www.nxtrent.com provides simple captables for people investing in
              real estates.
            </h5>
            <br />
            <b-button
              :to="{ name: 'Article', params: { title: 'How to Join' } }"
              class="shadow mb-auto"
              variant="dark"
            >
              How to Join
              <b-icon-arrow-right scale="0.8" />
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dotenv from 'dotenv'
dotenv.config()
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  created() {
    process.env
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-item a:after {
  content: '»';
}
#marketing-card-1 {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
